import React, { useState, useEffect, useContext } from "react";
import { TopicContext } from "./TopicContext";
import "./Carousel.css";
import "./slide.css";

export default function Slide(slide, pet) {
  //eslint-disable-next-line
  const { carouselIndex, setCarouselIndex } = useContext(TopicContext);
 //eslint-disable-next-line
  const [index, setIndex] = useState(1);
  const [info1, setInfo1] = useState(`lorem`);
  const [info2, setInfo2] = useState(`lorem`);
  const [info3, setInfo3] = useState(`lorem`);
  const [title, setTitle] = useState("lorem");
  const [alt, setAlt] = useState(``);
  const [imageURL, setImageURL] = useState(``);
  const [footnote, setFootnote] = useState(``);

  useEffect(() => {
    setIndex(slide.index);
    setInfo1(slide.info1);
    setInfo2(slide.info2);
    setInfo3(slide.info3);
    setTitle(slide.title);
    setFootnote(slide.footnote);
    setImageURL(slide.imageURL);
    setAlt(slide.alt);
  }, [slide]);

  return (
    <>
      <div className="page-container">
        <div className="media-container" id="primary">
          <div className="list-container">
            <ul className="data-list">
              <h3 className="title">{title}</h3>
              <li className="list-item" id="list-item-one">
                {info1}
              </li>
              {info2 ? (
                <li className="list-item" id="list-item-two">
                  {info2}
                </li>
              ) : null}
              {info3 ? (
                <li className="list-item" id="list-item-three">
                  {info3}
                </li>
              ) : null}
            </ul>
            <p className="footnote">{footnote}</p>
          </div>
          <div className="science-image-container">
            <img className="science-image" src={imageURL} alt={alt} />
          </div>
        </div>
      </div>
    </>
  );
}
